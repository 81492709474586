var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-simple-table',[(_vm.plans.length)?_c('thead',[_c('tr',[(_vm.sorting)?_c('th'):_vm._e(),_c('th',[_vm._v("Plan Name")]),(!_vm.sorting)?_c('th',[_vm._v("Plan Description")]):_vm._e(),(!_vm.sorting)?_c('th',[_vm._v("Plan Features")]):_vm._e(),_c('th',[_vm._v("Plan Plans")]),_c('th',{attrs:{"width":"15%"}},[_vm._v("Active")]),(_vm.listFor != 'Draft')?_c('th',[_vm._v("Archive")]):_vm._e()])]):_vm._e(),_c('tbody',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMore),expression:"loadMore"}],attrs:{"infinite-scroll-disabled":_vm.disableListScroll,"infinite-scroll-immediate-check":"","infinite-scroll-distance":"50","id":"dragTable"}},_vm._l((_vm.plans),function(plan){return _c('tr',{key:plan.id,staticClass:"new-tab-link-row clickable ",class:{ 'sortableRow handle sortHandle': _vm.sorting }},[(_vm.sorting)?_c('td',[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.drag)+" ")])],1):_vm._e(),_c('td',{staticClass:"py-4",attrs:{"width":"20%"}},[_vm._v(" "+_vm._s(plan.name)+" "),(plan.is_featured)?_c('v-icon',{attrs:{"small":"","color":"var(--v-primary-base)"}},[_vm._v(" "+_vm._s(_vm.icons.starCircle)+" ")]):_vm._e(),_c('router-link',{staticClass:"row-link",attrs:{"to":{
              name: _vm.redirectPathName,
              params: { id: plan.id },
              meta: { stage: 1 },
            }}})],1),(!_vm.sorting)?_c('td',{staticClass:"py-4",attrs:{"width":"40%"}},[_vm._v(" "+_vm._s(plan.description)+" "),_c('router-link',{staticClass:"row-link",attrs:{"to":{
              name: _vm.redirectPathName,
              params: { id: plan.id },
              meta: { stage: 1 },
            }}})],1):_vm._e(),(!_vm.sorting)?_c('td',{staticClass:"py-4",attrs:{"width":"20%"}},[_vm._v(" "+_vm._s(_vm._f("readable")(plan.features))+" "),_c('router-link',{staticClass:"row-link",attrs:{"to":{
              name: _vm.redirectPathName,
              params: { id: plan.id },
              meta: { stage: 1 },
            }}})],1):_vm._e(),_c('td',[_vm._v(" "+_vm._s(_vm._f("readable")(_vm.displayPlans(plan.plans)))+" "),_c('router-link',{staticClass:"row-link",attrs:{"to":{
              name: _vm.redirectPathName,
              params: { id: plan.id },
              meta: { stage: 1 },
            }}})],1),_c('td',{attrs:{"width":"10%"}},[(!plan.active_from && !plan.active_to)?_c('span',[_vm._v("Yes")]):(plan.active_from && !plan.active_to)?_c('span',[_vm._v(" "+_vm._s(_vm.tzFormat(plan.active_from))+" ")]):(!plan.active_from && plan.active_to)?_c('span',[_vm._v(" "+_vm._s(_vm.tzFormat(plan.active_to))+" ")]):(plan.active_from && plan.active_to)?_c('span',[_c('div',[_vm._v(_vm._s(_vm.tzFormat(plan.active_from)))]),_vm._v(" to "+_vm._s(_vm.tzFormat(plan.active_to))+" ")]):_vm._e(),_c('router-link',{staticClass:"row-link",attrs:{"to":{
              name: _vm.redirectPathName,
              params: { id: plan.id },
              meta: { stage: 1 },
            }}})],1),(_vm.listFor != 'Draft')?_c('td',[(_vm.listFor != 'Archived')?_c('v-btn',{staticClass:"bg-primary-gradient primary ml-auto",attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.archive(plan, true)}}},[_vm._v("Archive")]):_vm._e(),(_vm.listFor == 'Archived')?_c('v-btn',{staticClass:"bg-primary-gradient primary ml-auto",attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.archive(plan, false)}}},[_vm._v("Unarchive")]):_vm._e()],1):_vm._e()])}),0),(_vm.loading)?_c('table-skeleton-loader',{attrs:{"column":"5","row":""}}):_vm._e()],1),(!_vm.hasPlans && !_vm.loading)?_c('no-list',{attrs:{"details":"No Available Plans"}}):_vm._e(),_c('confirm-dialog',{ref:"confirmArchiving",attrs:{"has-custom-cancel":"true"},on:{"close":_vm.confirmed}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }