<template>
  <div>
    <v-simple-table>
      <thead v-if="plans.length">
        <tr>
          <th v-if="sorting"></th>
          <th>Plan Name</th>
          <th v-if="!sorting">Plan Description</th>
          <th v-if="!sorting">Plan Features</th>
          <th>Plan Plans</th>
          <th width="15%">Active</th>
          <th v-if="listFor != 'Draft'">Archive</th>
        </tr>
      </thead>

      <tbody
        v-infinite-scroll="loadMore"
        :infinite-scroll-disabled="disableListScroll"
        infinite-scroll-immediate-check
        infinite-scroll-distance="50"
        id="dragTable"
      >
        <tr
          v-for="plan in plans"
          :key="plan.id"
          class="new-tab-link-row clickable "
          :class="{ 'sortableRow handle sortHandle': sorting }"
        >
          <td v-if="sorting">
            <v-icon> {{ icons.drag }} </v-icon>
          </td>
          <td width="20%" class="py-4">
            {{ plan.name }}
            <v-icon v-if="plan.is_featured" small color="var(--v-primary-base)">
              {{ icons.starCircle }}
            </v-icon>
            <router-link
              :to="{
                name: redirectPathName,
                params: { id: plan.id },
                meta: { stage: 1 },
              }"
              class="row-link"
            />
          </td>
          <td v-if="!sorting" width="40%" class="py-4">
            {{ plan.description }}
            <router-link
              :to="{
                name: redirectPathName,
                params: { id: plan.id },
                meta: { stage: 1 },
              }"
              class="row-link"
            />
          </td>
          <td v-if="!sorting" width="20%" class="py-4">
            {{ plan.features | readable }}
            <router-link
              :to="{
                name: redirectPathName,
                params: { id: plan.id },
                meta: { stage: 1 },
              }"
              class="row-link"
            />
          </td>
          <td>
            {{ displayPlans(plan.plans) | readable }}
            <router-link
              :to="{
                name: redirectPathName,
                params: { id: plan.id },
                meta: { stage: 1 },
              }"
              class="row-link"
            />
          </td>
          <td width="10%">
            <span v-if="!plan.active_from && !plan.active_to">Yes</span>
            <span v-else-if="plan.active_from && !plan.active_to">
              {{ tzFormat(plan.active_from) }}
            </span>
            <span v-else-if="!plan.active_from && plan.active_to">
              {{ tzFormat(plan.active_to) }}
            </span>
            <span v-else-if="plan.active_from && plan.active_to">
              <div>{{ tzFormat(plan.active_from) }}</div>
              to {{ tzFormat(plan.active_to) }}
            </span>

            <router-link
              :to="{
                name: redirectPathName,
                params: { id: plan.id },
                meta: { stage: 1 },
              }"
              class="row-link"
            />
          </td>
          <td v-if="listFor != 'Draft'">
            <v-btn
              v-if="listFor != 'Archived'"
              :disabled="loading"
              class="bg-primary-gradient primary ml-auto"
              @click="archive(plan, true)"
              >Archive</v-btn
            >
            <v-btn
              v-if="listFor == 'Archived'"
              :disabled="loading"
              class="bg-primary-gradient primary ml-auto"
              @click="archive(plan, false)"
              >Unarchive</v-btn
            >
          </td>
        </tr>
      </tbody>
      <table-skeleton-loader v-if="loading" column="5" row />
    </v-simple-table>

    <no-list v-if="!hasPlans && !loading" details="No Available Plans" />
    <confirm-dialog
      has-custom-cancel="true"
      ref="confirmArchiving"
      @close="confirmed"
    />
  </div>
</template>

<script>
import TableSkeletonLoader from '@/components/elements/loaders/TableSkeleton'
import NoList from '@/components/elements/NoList'
import infiniteScroll from 'vue-infinite-scroll'
import { mdiDrag, mdiStarCircle } from '@mdi/js'
import Sortable from 'sortablejs'
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import { mapActions, mapMutations } from 'vuex'
import moment from 'moment-timezone'
import { includes, map } from 'lodash'
import Event from '@/services/eventBus'

export default {
  name: 'PlansListTable',

  props: {
    plans: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    sorting: {
      type: Boolean,
      default: false,
    },

    meta: {
      type: Object,
      required: true,
    },
    listFor: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      icons: {
        drag: mdiDrag,
        starCircle: mdiStarCircle,
      },
      reorderedPlans: [],
    }
  },

  components: {
    NoList,
    TableSkeletonLoader,
    ConfirmDialog,
  },

  directives: {
    infiniteScroll,
  },

  computed: {
    hasPlans() {
      return !!this.plans.length
    },

    disableListScroll() {
      return this.loading || this.meta.current_page >= this.meta.last_page
    },

    redirectPathName() {
      return includes(this.$route.name, 'active')
        ? 'plan.informations'
        : 'new.plan'
    },
  },

  methods: {
    ...mapActions({
      archivePlan: 'plans/archivePlan',
    }),

    ...mapMutations({
      updateArchivedList: 'plans/shiftPlanOnArchiveToTargetList',
    }),
    loadMore() {
      this.$emit('loadMore', this.meta.current_page + 1)
    },

    displayPlans(plans) {
      return map(plans, (plan) => {
        return plan.name
      })
    },

    showInformations(planId) {
      this.$emit('show', planId)
    },

    dragReorder({ oldIndex, newIndex }) {
      const movedItem = this.plans.splice(oldIndex, 1)[0]
      this.plans.splice(newIndex, 0, movedItem)
    },
    tzFormat(date) {
      return date
        ? moment(date)
            .tz('Australia/Brisbane')
            .format('lll')
        : null
    },

    async archive(plan, setArchive) {
      const confirm = await this.$refs.confirmArchiving.open(
        `${setArchive ? 'Archive' : 'Unarchive'} Plan`,
        `Are you sure you want to ${
          setArchive ? 'archive' : 'unarchive'
        } this plan?`
      )

      if (!confirm) return

      this.loading = true

      try {
        await this.archivePlan({ id: plan.id, setArchive })
        // await this.updateArchivedList({ plan, setArchive })

        setTimeout(() => {
          this.loading = false
          if (setArchive) {
            Event.$emit('update-current-plans-list')
          } else {
            Event.$emit('update-archived-plans-list')
          }
        }, 900)
      } catch (err) {
        this.loading = false
        this.$refs.confirmArchiving.open(
          'Error',
          'There was an error archiving the selected event'
        )
      }
    },
  },

  mounted() {
    /* eslint-disable no-new */
    new Sortable(document.querySelector('#dragTable'), {
      draggable: '.sortableRow',
      handle: '.sortHandle',
      onEnd: this.dragReorder,
    })
  },
}
</script>
